import LayoutA1 from "../../components/layout-a1"
import React from "react"
import './raceInfo.css'


export default class News extends React.Component {
  render() {
    const contents=this.props.pageContext.element.contents

    const contentlist=contents.map((content,index)=>
      <div key={index} className="content">
        <p>{content.title}</p>
        {content.content.map((item,index)=>
          <div key={index}>
            {item.type==='text'&&<p key={index}>{item.text}</p>}
            {item.type==='img'&&<img alt="hello" key={index}  src={item.src}/>}
          </div>

        )}
      </div>)
    return(
      <LayoutA1 location={this.props.location} >
        <div className="rules">
          <div>{contentlist}</div>
        </div>
      </LayoutA1>
    )
  }
}
